<template>
  <div>
    <v-dialog v-model="openDialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          Modifier
          <v-spacer/>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-6">
          <v-text-field
              v-model="form.name"
              :error-messages="formErrors && formErrors.name ? formErrors.name[0] : ''
               "
              label="Titre de problème *"
              @input="
                formErrors && formErrors.name ? (formErrors.name = '') : false
                "
          />
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="btnLoading"
                 color="primary"
                 depressed
                 @click="save"
          >
            <v-icon left>mdi-content-save</v-icon>
            Savegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      btnLoading: false,
      formErrors: {},
      form: {
        id: "",
        name: "",
      },
      openDialog: false,
    };
  },
  methods: {
    open(item) {
      this.form.id = item.id;
      this.form.name = item.name;
      this.openDialog = true;
    },
    close() {
      this.openDialog = false;
    },
    save() {
      this.btnLoading = true;
      HTTP.post("/v1/errors-reported/list-errors/" + this.form.id + '/update', this.form)
          .then(() => {
            this.$successMessage = "Enregistrement avec succès";
            this.btnLoading = false;
            this.close();
            this.$emit("refresh");
          })
          .catch((err) => {
            this.btnLoading = false;
            this.formErrors = err.response.data.errors;
          });
    }
  },
  created() {
  },
};
</script>