<template>
  <div>
    <v-container fluid>
      <div class="main">

        <div class="text-end mb-3">
          <v-btn color="gifty rounded-lg"
                 depressed
                 dark
                 @click="addError">
            <v-icon left>mdi-plus</v-icon>
            Ajouter
          </v-btn>
        </div>

        <v-card class="rounded-lg shadow">

          <v-card-title>
            <div>
              <v-text-field
                  v-model="keyword"
                  append-icon="mdi-magnify"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Rechercher..."
                  rounded
                  single-line
                  v-on:keyup.enter="getItems"
              />
            </div>
          </v-card-title>

          <v-card-text class="pa-0">

            <v-skeleton-loader v-if="isLoading" type="table"/>

            <div v-if="listErrors.length && !isLoading">

              <v-divider/>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Titre de problème</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in listErrors" :key="item.id">
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      <v-btn icon @click="remove(item)">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                      <v-btn icon @click="updateError(item)">
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <div v-if="!listErrors.length && !isLoading">
              <NoResults @reload="getItems"/>
            </div>

          </v-card-text>
        </v-card>

        <AddError ref="addError" @refresh="getItems"/>
        <updateError ref="updateError" @refresh="getItems"/>

      </div>
    </v-container>
  </div>
</template>

<script>
import AddError from "./components/AddError.vue";
import updateError from "./components/UpdateError.vue";
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults.vue";

export default {
  components: {NoResults, AddError, updateError},
  data() {
    return {
      keyword: null,
      id: null,
      isLoading: false,
      listErrors: [],
    };
  },
  methods: {
    getItems() {
      this.isLoading = true;
      HTTP.get("v1/errors-reported/list-errors", {
        params: {
          keyword: this.keyword
        }
      }).then((res) => {
        this.listErrors = res.data.data;
        this.isLoading = false;
      }).catch((err) => {
        this.isLoading = false;
        console.log(err);
      });
    },
    addError() {
      this.$refs.addError.open();
    },
    updateError(item) {
      this.$refs.updateError.open(item);
    },

    remove(id) {
      this.id = id;
      this.$confirm_dialog = true;
    },
    deleteItem() {
      HTTP.delete("v1/errors-reported/list-errors/" + this.id + '/delete')
          .then(() => {
            this.$successMessage = "Cet élément a été supprimé avec succès.";
            this.getItems();
          }).catch((err) => {
        console.log(err);
      });
    },
  },
  created() {
    this.getItems()
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem();
        this.$is_confirm = false;
      }
    },
    keyword() {
      this.getItems();
    },
  }
};
</script>